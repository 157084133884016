var defaultState = 0;

function isBeginAPICall(type){
    return type.substring(type.length - 6) === "_BEGIN";
}

function isEndAPICall(type){
    return type.substring(type.length - 8) === "_SUCCESS" || type.substring(type.length - 8) === "_FAILURE";
}

export default function apiStatusReducer(state = defaultState, action) {
    if (isBeginAPICall(action.type)){
        return state + 1
    } else if (isEndAPICall(action.type)){
        return state - 1
    }


    return state;
}