import React, { Component } from "react";
import "./FormControl.css";
import ReactSlider from "react-slider";
import PropTypes from 'prop-types';
//import debounce from 'lodash.debounce';
// debounce the slider change function so that it does not get called so much while changing the slider

class FormControl extends Component {
    state = { 
        value: this.props.control.defaultValue 
    }
    
    handleSliderChange = value => {
        this.setState({value: value},this.props.control.onChange(value));
    }

    handleSliderChangeStart = () => {

    }

    handleSliderChangeComplete = () => {

    }

    render() {
        if(this.props.control.controlType == "slider") {
            return (
                <div className="form-control-container">
                    <div className="form-control-label-container">
                        <h1 className="form-control-label">{this.props.control.controlLabel}</h1>
                    </div>
                    <div className="form-control-body">
                        <ReactSlider className="horizontal-slider"
                                thumbClassName="slider-thumb"
                                trackClassName="slider-track"
                                min={1}
                                max={25}
                                value={this.state.value}
                                onChangeStart={this.handleSliderChangeStart}
                                onChange={this.handleSliderChange}
                                onChangeComplete={this.handleSliderChangeComplete} />
                        <p className="slider-value-show">{this.state.value + this.props.control.valueDisplayPostfix}</p>
                    </div>
                </div>
            )
        } else if(this.props.control.controlType == "image") {
            return (
                <div className="form-control-container">
                    <div className="form-control-body">
                        
                    </div>
                </div>
            )
        } else if(this.props.control.controlType == "label") {
            return(
                <div className="form-control-container">
                     <div className="form-control-label-container">
                        <h1 className="form-control-label">{this.props.control.controlLabel}</h1>
                    </div>
                </div>
            )
        } else if(this.props.control.controlType == "button") {
            return(
                <p>button control</p>
            )
        } else {
            //return empty control if control type is not valid
            return(
                <></>
            )
        }
    }
}

FormControl.propTypes = {
    control: PropTypes.object.isRequired
}

export default FormControl