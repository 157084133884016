var defaultState = {
    hasError: false,
    errorMessage: ""
};

function isError(type){
    return type.substring(type.length - 8) === "_FAILURE";
}

function clearError(type){
    return type.substring(type.length - 8) === "_SUCCESS";
}

export default function errorReducer(state = defaultState, action) {
    if (isError(action.type)){
        return {
            ...state,
            hasError: true,
            errorMessage: action.data
        }
    } else if (clearError(action.type)){
        return {
            ...state,
            hasError: false,
            errorMessage: ""
        }
    }


    return state;
}