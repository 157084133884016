import React, { Component } from "react";
import "./ErrorOverlay.css"
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react'

import { connect } from 'react-redux';
//import * as actions from '../../redux/actions/actions';

class ErrorOverlay extends Component {

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }
    render() {
        if(this.props.error.hasError){
            return (
                <div className="error-overlay-container">
                    <Icon className="error-icon" icon="bx:error" />
                    <div className="error-overlay-message">{this.props.error.errorMessage}</div>
                </div>
            )
        } else {
            return(<></>)
        }
    }
}

ErrorOverlay.propTypes = {
    dispatch: PropTypes.func.isRequired,
    error: PropTypes.object
  }
  
  export default connect((state) => {
  
    return {
        error: state.error
    }
  
  })(ErrorOverlay);